const initialState = {
    export_loading: false,
    avea_loading: false,
    is_loading: false,
    order_id:"",
    show_upload_reconcilation_modal: false,
    total_result: 0,
    page:1,
    limit: 10,
    sortBy: {"name":"order_date", "order": 1},
    pagination_limit:4,
    payment_reconcilation_list: [],
    totalRecords: 0,
    pending_reconciled_count: 0,
    reconciled_count: 0,
    filter_type: "No",
}
  
const Orders = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'PAYMENT_RECONCILATION_LIST':
            return {...state, ...action.payload }
        case 'UPLOAD_PAYMENT_RECONCILATION_MODAL':
            return {...state, ...action.payload }
        case 'RECONCILED_EXPORT_LOADER':
            return {...state, ...action.payload }
        case 'LOADING':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Orders;