const initialState = {
	export_loading: false,
	is_loading: false,
	order_id: "",
	show_upload_order_modal: false,
	total_result: 0,
	page: 1,
	limit: 10,
	sortBy: { "name": "order_date", "order": -1 },
	pagination_limit: 4,
	order_list: [],
}

const LateOrders = (state = initialState, action) => {
	if (state === null || state === undefined || action === null) {
		return { ...state }
	}
	switch (action.type) {
		case 'LATE_ORDERS_LIST':
			return { ...state, ...action.payload }
		case 'LATE_ORDERS_LOADING':
			return { ...state, ...action.payload }
		default:
			return state;
	}
}

export default LateOrders;
