const initialState = {
    export_loading: false,
    is_loading: false,
    product_id:"",
    show_add_product_modal: false,
    show_upload_product_modal: false,
    total_product: 0,
    page:1,
    limit: 10,
    sortBy: {"name":"sku", "order": 1},
    pagination_limit:4,
    product_list: [],
}
  
const Products = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'PRODUCT_LIST':
            return {...state, ...action.payload }
        case 'PRODUCT_MODAL':
            return {...state, ...action.payload }
        case 'UPLOAD_PRODUCT_MODAL':
            return {...state, ...action.payload }
        case 'EXPORT_LOADER':
            return {...state, ...action.payload }
        case 'LOADING':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Products;