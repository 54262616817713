const initialState = {
    is_loading: false,
    show_reset_modal: false,
    show_delete_modal: false,
    show_add_user_modal: false,
    show_manage_permission_modal: false,
    show_block_user_modal: false,
    active_status: true,
    user_id: "",
    fcm_user_id: "",
    user_email: "",
    total_user: 0,
    page:1,
    limit: 10,
    sortBy: "created_at:desc",
    pagination_limit:5,
    user_list: [],
}
  
const User = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'USER_LIST':
            return {...state, ...action.payload }
        case 'RESET_PASSWORD':
            return {...state, ...action.payload }
        case 'ADD_USER':
                return {...state, ...action.payload }
        case 'DELETE_USER':
            return {...state, ...action.payload }
        case 'BLOCK_USER':
            return {...state, ...action.payload }
        case 'MANAGE_USER_PERMISSION':
            return {...state, ...action.payload }
        case 'LOADING':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default User;