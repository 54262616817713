const initialState = {
    export_all_mail_order_loading: false,
    export_unfulfilled_mail_order_loading: false,
    export_payment_history_loading: false,
    export_payment_details: false
}
  
const Products = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'EXPORT_MAIL_ALL_ORDER_LOADING':
            return {...state, ...action.payload }
        case 'EXPORT_UNFULFILLED_MAIL_ALL_ORDER_LOADING':
            return {...state, ...action.payload }
        case 'EXPORT_PAYMENT_HISTORY':
            return {...state, ...action.payload }
        case 'EXPORT_PAYMENT_DETAILS':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Products;