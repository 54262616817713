const initialState = {
    export_dead_tracking_montoring_loading: false,
}
  
const DeadTrackingMonitoring = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'DEAD_TRACKING_MONITORING_LOADER':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default DeadTrackingMonitoring;