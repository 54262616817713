const initialState = {
    export_loading: false,
}
  
const Expenses = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'EXPENSE_EXPORT_LOADER':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Expenses;