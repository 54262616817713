const initialState = {
    export_loading: false,
    is_loading: false,
    order_id:"",
    show_upload_order_modal: false,
    total_result: 0,
    page:1,
    limit: 10,
    sortBy: {"name":"order_date", "order": -1},
    pagination_limit:4,
    order_list: [],
}
  
const Orders = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'ORDER_LIST':
            return {...state, ...action.payload }
        case 'UPLOAD_ORDER_MODAL':
            return {...state, ...action.payload }
        case 'EXPORT_LOADER':
            return {...state, ...action.payload }
        case 'LOADING':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Orders;