import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Admin from './reducer/admin.reducer.js';
import Sidebar from './reducer/sidebar.reducer.js';
import User from './reducer/user.reducer.js';
import Products from './reducer/product.reducer.js';
import MyProfile from './reducer/myProfile.reducer.js';
import Orders from './reducer/orders.reducer.js';
import paymentReconcilation from './reducer/paymentReconcilation.reducer.js';
import Returns from './reducer/returns.reducer.js';
import Expenses from './reducer/expense.reducer.js';
import ExportTemplate from './reducer/exportTemplate.reducer.js';
import Warehouse from './reducer/warehouse.reducer';
import OrderMonitoring from './reducer/orderMonitoring.reducer.js';
import Supplier from './reducer/supplier.reducer';
import DeadTrackingMonitoring from './reducer/deadTracking.reducer';
import ProductCategory from './reducer/productCategory.reducer';
import LateOrders from './reducer/lateOrders.reducer';

const AppReducers = combineReducers({
  Admin, Sidebar, User, Products, MyProfile, Orders, LateOrders,
  paymentReconcilation, Returns, Expenses, ExportTemplate, OrderMonitoring, Warehouse, Supplier, DeadTrackingMonitoring, ProductCategory
});

const rootReducer = (state, action) => {
  return AppReducers(state, action);
}

// let store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
let store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
