const initialState = {
    profile_image: false,
    name: "",
    self_id: "",
    user_type: "",
    user_color: "",
    dark_mode: "",
    user_permissions: {},
    groupon_ticket_cookie_status: false,
    chat_users: [],
    email: "",
}

const MyProfile = (state = initialState, action) => {
    if (state === null || state === undefined || action === null) {
        return { ...state }
    }
    switch (action.type) {
        case 'MY_PROFILE':
            return { ...state, ...action.payload }
        case 'SET_CHAT_USER':
            return { ...state, ...action.payload }
        default:
            return state;
    }
}

export default MyProfile;
