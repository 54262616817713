const initialState = {
    is_loading: true,
}

const Admin = (state = initialState, action) =>{
    // console.log('Reducer', action);
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case "USER_LOGIN" :
            // const userData = action.payload;
            return {
                ...state,
                is_loading: false
            };
        default:
            return state;
    }

}

export default Admin;