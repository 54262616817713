const initialState = {
  is_loading: false,
  product_cat_id: "",
  show_add_product_cat_modal: false,
  total_product_cat: 0,
  page: 1,
  limit: 10,
  sortBy: { "name": 1, "status": 1 },
  pagination_limit: 4,
  product_cat_list: [],
}

const ProductCategory = (state = initialState, action) => {
  if (state === null || state === undefined || action === null) {
    return { ...state }
  }
  switch (action.type) {
    case 'PRODUCT_CAT_LIST':
      return { ...state, ...action.payload }
    case 'PRODUCT_CAT_MODAL':
      return { ...state, ...action.payload }
    case 'LOADING':
      return { ...state, ...action.payload }
    default:
      return state;
  }
}

export default ProductCategory;
