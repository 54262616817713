const initialState = {
    export_order_loading: false,
    export_product_loading: false,
    export_expense_loading: false,
    export_analyitic_orders_loading: false,
    export_batch_orders_loading: false,
    export_existing_product_loading: false,
    export_shipping_matrix: false,
    shipping_cost_recalculate_loading: false
}
  
const Orders = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'ORDER_TEMPLATE_LOADER':
            return {...state, ...action.payload }
        case 'PRODUCT_TEMPLATE_LOADER':
            return {...state, ...action.payload }
        case 'EXPENSE_TEMPLATE_LOADER':
            return {...state, ...action.payload }
        case 'EXPORT_ANALYITIC_ORDERS':
            return {...state, ...action.payload }
        case 'EXPORT_BATCH_ORDERS':
            return {...state, ...action.payload }
        case 'EXPORT_EXISTING_PRODUCT':
            return {...state, ...action.payload }
        case 'EXPORT_SHIPPING_MATRIX':
            return {...state, ...action.payload }
        case 'SHIPPING_COST_RECALCULATING':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default Orders;